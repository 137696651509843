import React, { useState, useCallback, useEffect } from 'react';
import {
  FormLayout,
  TextField,
  Button,
  SettingToggle,
  TextStyle,
  Page,
  Banner,
  RadioButton,
  Card,
  Text,
  Grid,
} from '@shopify/polaris';
import Loading from '../components/Loading';
import '../styles/components/Settings.css';

const Setting = () => {
  const shop = new URLSearchParams(location.search).get('shop');
  const host = new URLSearchParams(location.search).get('host');
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [dateSelect, setDateSelect] = useState();
  const [dateForm, setDateForm] = useState('');
  const [user, setUser] = useState('');

  const [rut, setRut] = useState('');
  const [courierText, setCourierText] = useState('');
  const [preparationDays, setPreparationdays] = useState('');
  const [token, setToken] = useState('false');
  const [courier, setCourier] = useState('si');
  const [dates, setDates] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleChange = useCallback(
    (_checked, newValue) => setCourier(newValue),
    []
  );
  const handleUser = useCallback((newValue) => setUser(newValue), []);
  const handleToken = useCallback((newValue) => setToken(newValue), []);
  const handleRut = useCallback((newValue) => setRut(newValue), []);
  const handleDays = useCallback(
    (newValue) => setPreparationdays(newValue),
    []
  );
  const handleCourierText = useCallback(
    (newValue) => setCourierText(newValue),
    []
  );

  const contentStatus = !active ? 'Activar' : 'Desactivar';
  useEffect(() => {
    setIsLoading(true);
    window
      .fetch(
        `${process.env.REACT_APP_BASEURL}/setting?shop=${shop}&&host=${host}`,
        {
          method: 'GET',
        }
      )
      .then((response) => response.json())
      .then((resp) => {
        if (resp.url) {
          setUser(resp.url);
        }
        if (resp.token) {
          setToken(resp.token);
        }
        if (resp.automatic) {
          setActive(resp.automatic === '1');
        }
        if (resp.courier) {
          setCourier(resp.courier);
        }
        if (resp.preparationdays) {
          setPreparationdays(resp.preparationdays);
        }
        if (resp.rut) {
          setRut(resp.rut);
        }
        if (resp.courierText) {
          setCourierText(resp.courierText);
        }
        if (resp.holidays) {
          setDates(JSON.parse(resp.holidays));
        }
        setIsLoading(false);
      })
      .catch(function err() {
        setIsLoading(false);
      });
  }, []);

  const submitForm = () => {
    const data = {
      url: user,
      token,
      automatic: active,
      shop,
      rut,
      preparationdays: preparationDays,
      courier,
      courierText,
      holidays: JSON.stringify(dates),
    };

    window
      .fetch(
        `${process.env.REACT_APP_BASEURL}/setting?shop=${shop}&host=${host}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          method: 'POST',
        }
      )
      .then((response) => response.json())
      .then((resp) => {
        setIsMessage(true);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setIsLoading(false);
      })
      .catch(function err() {
        setIsLoading(false);
      });
  };

  const updateActive = (status) => {
    const data = {
      automatic: status ? 1 : 0,
      shop,
    };

    window
      .fetch(
        `${process.env.REACT_APP_BASEURL}/setting?shop=${shop}&host=${host}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          method: 'POST',
        }
      )
      .then((response) => response.json())
      .then((resp) => {
        setIsMessage(true);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setIsLoading(false);
      })
      .catch(function err() {
        setIsLoading(false);
      });
  };
  const handleToggle = () => {
    if (active) {
      updateActive(0);
      setActive(false);
    } else {
      updateActive(1);
      setActive(true);
    }
  };

  const handleDate = (value) => {
    console.log(value);
    setDateSelect(value);
  };

  const addDays = () => {
    setDates([...dates, dateSelect]);
  };

  const removeDays = () => {
    const temp = [...dates];
    temp.splice(dateForm, 1);
    setDates(temp);
  };

  const showFile = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const provText = text.split(';');
      const provSig = [...dates];
      Array.prototype.push.apply(provSig, provText);
      setDates(provSig);
    };
    reader.readAsText(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Page title="Configuración">
            {isMessage && (
              <Banner
                title="Modificación realizada"
                status="success"
                onDismiss={() => {
                  setIsMessage(false);
                }}
              />
            )}

            <FormLayout>
              <Card title="Datos de la tienda" sectioned>
                <TextField label="Nombre de tienda" value={shop} disabled />
                <TextField
                  label="Correo electrónico Sendu"
                  value={user}
                  onChange={handleUser}
                  error={
                    user.length < 1 ? 'Correo electrónico es requerido' : null
                  }
                />
                <TextField
                  label="Token Sendu"
                  value={token}
                  onChange={handleToken}
                  error={token.length < 1 ? 'Token es requerido' : null}
                />
                <TextField
                  label="RUT Tienda"
                  value={rut}
                  onChange={handleRut}
                  error={rut.length < 1 ? 'RUT tienda es requerido' : null}
                />
                <p>¿Mostrar nombre de courier?</p>
                <RadioButton
                  label="Si"
                  id="si"
                  name="courier"
                  checked={courier === 'si'}
                  onChange={handleChange}
                />
                <RadioButton
                  label="No"
                  id="no"
                  name="courier"
                  checked={courier === 'no'}
                  onChange={handleChange}
                />
                {courier === 'no' && (
                  <TextField
                    value={courierText}
                    onChange={handleCourierText}
                    helpText={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <span>
                        (Opcional) texto a mostrar si no se muestra el courier
                      </span>
                    }
                  />
                )}
                <TextField
                  label="Días de preparación del pedido"
                  value={preparationDays}
                  onChange={handleDays}
                  helpText={
                    <span>(Opcional) Días a sumar a la fecha de entrega</span>
                  }
                />
              </Card>

              <SettingToggle
                action={{
                  content: contentStatus,
                  onAction: handleToggle,
                }}
                enabled={active}
              >
                <Text variant="headingMd" as="h6">
                  Emisión y órden de flete
                </Text>
                <br />
                Estatus:
                <TextStyle variation="strong">
                  {' '}
                  {active ? 'Activo' : 'Desactivado'}
                </TextStyle>
                .
                <br />
                Emite de forma automática al finalizar la compra las órdenes de
                flete.
              </SettingToggle>

              <Card title="Dias festivos" sectioned>
                <Grid>
                  <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                    Estas fechas de días festivos no se tomarán
                    <br />
                    en cuenta para el cálculo de la fecha de entrega
                    <br />
                    <br />
                    <TextField
                      type="date"
                      name="expiration date"
                      value={dateSelect}
                      onChange={handleDate}
                    />
                    <Button type="button" onClick={() => addDays()}>
                      Agregar fecha
                    </Button>
                  </Grid.Cell>
                  <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                    Se debe subir un archivo .dat (sin formato) con las fechas,
                    separadas por punto y coma (;) - ejemplo:
                    aaaa-mm-dd;aaaa-mm-dd
                    <br />
                    <br />
                    <label className="file-input-container">
                      <input
                        type="file"
                        className="file-input"
                        onChange={showFile}
                        accept=".dat, .date"
                      />
                      <span className="file-name">
                        {selectedFile ? (
                          selectedFile.name
                        ) : (
                          <Text>Selecciona un archivo</Text>
                        )}
                      </span>
                    </label>
                  </Grid.Cell>
                </Grid>
                <div>
                  <br />
                  <br />
                  <label>Días seleccionados</label>
                  <select
                    multiple="multiple"
                    className="multiselect "
                    style={{
                      display: 'block',
                      height: '150px',
                      width: '8.2rem',
                    }}
                    onChange={(e) => setDateForm(e.target.value)}
                  >
                    {dates.map((item, key) => (
                      <option key={key} value={key}>
                        {item}
                      </option>
                    ))}
                  </select>

                  <Button type="button" onClick={() => removeDays()}>
                    Remover fecha
                  </Button>
                </div>
              </Card>
              <Button
                primary
                onClick={() => submitForm()}
                disabled={token.length < 1 || user.length < 1}
              >
                Guardar
              </Button>
            </FormLayout>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Page>
        </>
      )}
    </>
  );
};
export default Setting;
