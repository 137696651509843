import React, { useState, useEffect } from 'react';
import '../styles/components/Settings.css';
import { LegacyCard, Page, Loading, Layout, Text } from '@shopify/polaris';

const Log = () => {
  const shop = new URLSearchParams(location.search).get('shop');
  const [isLoading, setIsLoading] = useState(false);
  const [listProjects, setlistProjects] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    window
      .fetch(`${process.env.REACT_APP_BASEURL}/setting-log?shop=${shop}`, {
        method: 'GET',
      })
      .then((response) => response.json())
      .then((resp) => {
        let valores = resp.map((currElement) => {
          const response = JSON.parse(currElement.response);
          const data = {
            title: currElement.title,
            created: currElement.created,
            response: JSON.stringify(response),
          };
          return data;
        });
        setlistProjects(valores);
        setIsLoading(false);
      })
      .catch(function err() {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Page title="Logs del sistema">
          <Layout>
            <Layout sectioned oneHalf>
              <LegacyCard>
                {listProjects.map((item, index) => (
                  <LegacyCard.Section
                    key={`${index}-${item.title}`}
                    title={`${item.title} - ${item.created}`}
                  >
                    <Text style={{ wordWrap: 'break-word' }}>
                      {item.response}
                    </Text>
                  </LegacyCard.Section>
                ))}
              </LegacyCard>
            </Layout>
          </Layout>
        </Page>
      )}{' '}
    </>
  );
};

export default Log;
