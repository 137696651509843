// Routes.js
import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AppContext from '../context/AppContext';
import Home from '../containers/Home';
import Setting from '../containers/Settings';
import Log from '../containers/Log';
import Order from '../containers/Order';
import OrderDetail from '../containers/OrderDetail';
import Commune from '../containers/Commune';

function Routes() {
  return (
    <Switch>
      <Route exact path="/home" component={Home} />
      <Route exact path="/orders" component={Order} />
      <Route exact path="/order-detail/:id" component={OrderDetail} />
      <Route exact path="/settings/configure" component={Setting} />
      <Route exact path="/settings/log" component={Log} />
      <Route exact path="/settings/commune" component={Commune} />
      <PrivateRoute exact path="/checkout-ad">
        <Home />
      </PrivateRoute>
      <Route
        path="/account"
        render={({ match: { url } }) => (
          <>
            <Route exact path={`${url}/affiliation`} component={Home} />
          </>
        )}
      />
      <Route component={Order} />
    </Switch>
  );
}

function PrivateRoute({ children, ...rest }) {
  const { isAuth } = useContext(AppContext);
  const auth = { user: isAuth };
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );
}

export default Routes;
