import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Page, Text, Loading, Layout } from '@shopify/polaris';

const OrderDetail = () => {
  const { id } = useParams();
  const host = new URLSearchParams(location.search).get('host');
  const shop = new URLSearchParams(location.search).get('shop');
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [shopifyData, setShopifyData] = useState({});
  const [tracking, setTracking] = useState({});

  useEffect(() => {
    setIsLoading(true);
    window
      .fetch(
        `${process.env.REACT_APP_BASEURL}/order-detail/${id}?shop=${shop}&&host=${host}`,
        {
          method: 'GET',
        }
      )
      .then((response) => response.json())
      .then((resp) => {
        console.log(resp);
        setOrder(resp.order);
        setShopifyData(resp.shopify);
        setTracking(resp.tracking);
        setIsLoading(false);
      })
      .catch(function err() {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Page
          breadcrumbs={[
            {
              content: 'Órdenes',
              url: `/orders/?shop=${shop}&&host=${host}`,
            },
          ]}
          title="Detalle de Orden"
        >
          <Card title="Información de Envío">
            <Card.Section title="Número de Envío Sendu - #SP">
              <Text>{order?.number_order}</Text>
            </Card.Section>
            <Card.Section title="Nombre de Cliente">
              <Text>{shopifyData?.shipping_address?.name} </Text>
            </Card.Section>
            <Card.Section title="Teléfono de Cliente">
              <Text>{shopifyData?.shipping_address?.phone} </Text>
            </Card.Section>
            <Card.Section title="Email de Cliente">
              <Text>{tracking?.email} </Text>
            </Card.Section>
            <Card.Section title="Entrega">
              <p>{order?.forma_entrega} </p>
            </Card.Section>
            <Card.Section title="Información de Envío">
              <Text>Peso: {tracking?.weight}</Text>
              <Text>Altura: {tracking?.height}</Text>
              <Text>Longitud: {tracking?.large}</Text>
              <Text>Profundidad: {tracking?.deep}</Text>
            </Card.Section>
            <Card.Section title="Fecha estimada de entrega">
              <p>{order?.transit_days}</p>
            </Card.Section>
          </Card>
        </Page>
      )}
    </>
  );
};

export default OrderDetail;
