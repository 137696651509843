export default {
  host: null,
  token: null,
  cart: [],
  buyer: [],
  letter: [],
  numberPhone: '',
  payment: [],
  ubications: [],
  paymentPreview: [],
  userInfo: [],
  iziResponse: [],
};
