import React from 'react';
import ReactDOM from 'react-dom';

import App from './routes/App';
import { SenduProvider } from './context/SenduProvider';

ReactDOM.render(
  <SenduProvider>
    <App />
  </SenduProvider>,
  document.getElementById('app')
);
