import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import '@shopify/polaris/build/esm/styles.css';
import esTranslations from '@shopify/polaris/locales/es.json';
import { AppProvider } from '@shopify/polaris';
import { Provider } from '@shopify/app-bridge-react';
import Layout from '../components/Layout';
import AppContext from '../context/AppContext';

const App = () => {
  const { dispatch } = useContext(AppContext);
  const host = new URLSearchParams(location.search).get('host');
  const config = {
    apiKey: '2bc167fdc4204a60e277c5dca01b54c1',
    forceRedirect: process.env.NODE_ENV !== 'development',
    host,
  };

  useEffect(() => {
    dispatch({
      type: 'SET_HOST',
      payload: {
        host,
      },
    });
  }, []);

  return (
    <AppProvider i18n={esTranslations}>
      <Provider config={{ ...config, host }}>
        <Router>
          <Layout />
        </Router>
      </Provider>
    </AppProvider>
  );
};
export default App;
