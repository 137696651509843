import React, { useReducer, useEffect } from 'react';
import AppContext from './AppContext';
import initialState from '../initialState';

// Define el reducer para manejar las acciones
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_HOST':
      return { ...state, host: action.payload.host };
    case 'SET_SHOP':
      return { ...state, shop: action.payload.shop };
    default:
      return state;
  }
};

// Crea el proveedor del contexto
export const SenduProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      type: 'SET_HOST',
      payload: {
        host: new URLSearchParams(location.search).get('host'),
      },
    });
    dispatch({
      type: 'SET_SHOP',
      payload: {
        shop: new URLSearchParams(location.search).get('shop'),
      },
    });
  }, []);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
