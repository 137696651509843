import React, { useState, useEffect } from 'react';
import { FormLayout, Button, Page, Banner } from '@shopify/polaris';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import DualListBox from 'react-dual-listbox';
import Loading from '../components/Loading';

const Commune = () => {
  const shop = new URLSearchParams(location.search).get('shop');
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [user, setUser] = useState('');
  const [rut, setRut] = useState('');
  const [courierText, setCourierText] = useState('');
  const [preparationDays, setPreparationdays] = useState('');
  const [token, setToken] = useState('false');
  const [courier, setCourier] = useState('si');

  const options = [
    {
      label: 'Earth',
      options: [{ value: 'luna', label: 'Moon' }],
    },
    {
      label: 'Mars',
      options: [
        { value: 'phobos', label: 'Phobos' },
        { value: 'deimos', label: 'Deimos' },
      ],
    },
    {
      label: 'Jupiter',
      options: [
        { value: 'io', label: 'Io' },
        { value: 'europa', label: 'Europa' },
        { value: 'ganymede', label: 'Ganymede' },
        { value: 'callisto', label: 'Callisto' },
      ],
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    window
      .fetch(`${process.env.REACT_APP_BASEURL}/setting?shop=${shop}`, {
        method: 'GET',
      })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.url) {
          setUser(resp.url);
        }
        if (resp.token) {
          setToken(resp.token);
        }
        if (resp.automatic) {
          setActive(resp.automatic === 1);
        }
        if (resp.courier) {
          setCourier(resp.courier);
        }
        if (resp.preparationdays) {
          setPreparationdays(resp.preparationdays);
        }
        if (resp.rut) {
          setRut(resp.rut);
        }
        if (resp.courierText) {
          setCourierText(resp.courierText);
        }
        console.log(resp);
        setIsLoading(false);
      })
      .catch(function err() {
        setIsLoading(false);
      });
  }, []);

  const submitForm = () => {
    const data = {
      url: user,
      token,
      automatic: active,
      shop,
      rut,
      preparationdays: preparationDays,
      courier,
      courierText,
    };

    window
      .fetch(`${process.env.REACT_APP_BASEURL}/setting?shop=${shop}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        method: 'POST',
      })
      .then((response) => response.json())
      .then((resp) => {
        console.log(resp);
        setIsMessage(true);
        setIsLoading(false);
      })
      .catch(function err() {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Page title="Configuración de comunas">
            {isMessage && (
              <Banner
                title="Modificación Realizada"
                status="success"
                onDismiss={() => {
                  setIsMessage(false);
                }}
              />
            )}
            <FormLayout>
              <DualListBox options={options} />
              <Button
                outline
                onClick={() => submitForm()}
                disabled={token.length < 1 || user.length < 1}
              >
                Guardar Configuración
              </Button>
            </FormLayout>
          </Page>
        </>
      )}
    </>
  );
};
export default Commune;
